import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import PropTypes from "prop-types";
import classNames from "classnames";
import { pipe, get, map } from "lodash/fp";
import { mainMenuMobile, open } from "./style.module.scss";

const MenuMobile = ({ data, active }) => {
  const menuLinks = pipe(
    get("pages.edges"),
    map("menuPage")
  )(data);

  const services = pipe(
    get("allServices.edges"),
    map("service")
  )(data);

  return (
    <div
      id="main-menu-mobile"
      className={classNames(mainMenuMobile, { [open]: active })}
    >
      <ul>
        {services.map(service => (
          <li key={service.id}>
            <Link to={`/services/${service.slug}`}>{service.title}</Link>
          </li>
        ))}
        {menuLinks.map(link => (
          <li key={link.id}>
            <Link to={`/${link.link}`}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

MenuMobile.propTypes = {
  active: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        menuLinks: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string
          })
        )
      })
    })
  }).isRequired
};

const query = graphql`
  query {
    ...getMenuPages
    allServices: allContentfulService {
      edges {
        service: node {
          ...service
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={query}
    render={data => <MenuMobile {...props} data={data} />}
  />
);

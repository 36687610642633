import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, omit, tail, first } from "lodash/fp";

import MailingListForm from "./MailingListForm";
import postNetlifyForm from "../../utils/postNetlifyForm";
import validateForm from "../../utils/validateForm";

const FORM_NAME = "mailing_list";

const constraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: "^We could really use your full name"
    }
  },
  email: {
    email: {
      message: "^Hard to send emails without a valid email address"
    }
  }
};

const processForm = form => {
  const { name } = form;
  const nameParts = name.split(" ");
  return {
    ...omit("name", form),
    firstName: first(nameParts),
    lastName: tail(nameParts).join(" ")
  };
};

class MailingListFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: {}, form: { name: "", email: "" } };
    this.postForm = postNetlifyForm(FORM_NAME);
    this.validateForm = validateForm(constraints);
  }

  onBlur = e => {
    const { form } = this.state;
    const { name } = e.target;
    const newErrors = this.validateForm(form, name);
    this.setState(state => {
      const errors = isEmpty(newErrors)
        ? omit(name, state.errors)
        : { ...state.errors, ...newErrors };
      return {
        errors
      };
    });
  };

  onChange = e => {
    const { name, value } = e.target;
    this.setState(state => ({
      form: { ...state.form, [name]: value }
    }));
  };

  onSubmit = e => {
    e.preventDefault();
    const { form } = this.state;
    const { onSuccess, onFail } = this.props;
    const errors = this.validateForm(form);
    if (isEmpty(errors)) {
      this.postForm(processForm(form))
        .then(onSuccess)
        .catch(onFail);
    } else {
      this.setState(state => ({
        errors: { ...state.errors, ...errors }
      }));
    }
  };

  render() {
    const { form, errors } = this.state;
    return (
      <MailingListForm
        formName={FORM_NAME}
        form={form}
        errors={errors}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }
}

MailingListFormContainer.propTypes = {
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

MailingListFormContainer.defaultProps = {
  onSuccess: () => {},
  onFail: () => {}
};

export default MailingListFormContainer;

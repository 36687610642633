import validate from "validate.js";
import { pick } from "lodash/fp";

const validateForm = constraints => (form, key) => {
  const newErrors = validate(form, constraints);
  if (newErrors) {
    if (key) {
      return pick([key], newErrors);
    }
    return newErrors;
  }
  return {};
};

export default validateForm;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./style.module.scss";

const Contact = ({ email, telephoneNumber, instagram }) => (
  <Fragment>
    <h4>Contact</h4>
    <ul>
      <li>
        <a href={`mailto:${email}`} title="email us">
          <FontAwesomeIcon className={styles.icon} icon="envelope" />
          <span>{email}</span>
        </a>
      </li>
      <li>
        <a href={`tel:${telephoneNumber}`} title="call us">
          <FontAwesomeIcon className={styles.icon} icon="phone" />
          <span>{telephoneNumber}</span>
        </a>
      </li>
      <li>
        <a
          href={`https://instagram.com/${instagram}`}
          title="follow us on Instagram"
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={["fab", "instagram"]}
          />

          <span>@{instagram}</span>
        </a>
      </li>
    </ul>
  </Fragment>
);

Contact.propTypes = {
  email: PropTypes.string.isRequired,
  telephoneNumber: PropTypes.string.isRequired,
  instagram: PropTypes.string.isRequired
};

export default Contact;

import React from "react";
import PropTypes from "prop-types";

class Hamburger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerActive: false
    };
  }

  handleToggle = () => {
    const { hamburgerActive } = this.state;
    const { toggleMenu } = this.props;
    this.setState(prevState => ({
      hamburgerActive: !prevState.hamburgerActive
    }));
    toggleMenu(hamburgerActive);
  };

  render() {
    const { hamburgerActive } = this.state;
    return (
      <button
        title="Menu"
        id="toggle-main-menu-mobile"
        className={`hamburger hamburger--slider ${
          hamburgerActive ? "is-active" : ""
        }`}
        type="button"
        onClick={this.handleToggle}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    );
  }
}

Hamburger.propTypes = {
  toggleMenu: PropTypes.func.isRequired
};

export default Hamburger;

import formurlencoded from "form-urlencoded";

require("es6-promise").polyfill();
require("isomorphic-fetch");

const postNetlifyForm = formName => formData =>
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: formurlencoded({ "form-name": formName, ...formData })
  });

export default postNetlifyForm;

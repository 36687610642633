import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { reduce } from "lodash/fp";

import styles from "./style.module.scss";

const tags = ["h1", "h2", "h3", "h4", "ul", "p"];

const factory = tag => {
  const $tag = tag;

  const component = ({ children, className, ...rest }) => (
    // eslint-disable-next-line react/jsx-pascal-case
    <$tag {...rest} className={classNames(styles[tag], className)}>
      {children}
    </$tag>
  );

  component.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
  };

  component.defaultProps = {
    className: ""
  };

  component.displayName = tag.toUpperCase();

  return component;
};

const typeComponents = reduce((memo, tag) => {
  return {
    [tag.toUpperCase()]: factory(tag),
    ...memo
  };
}, {})(tags);

export const { H1, H2, H3, H4, UL, P } = typeComponents;

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { get, pipe, map, concat, join } from "lodash/fp";

const query = graphql`
  query {
    ...allBannerImages
    ...allContentfulTeamMembers
    contactInfo: contentfulContactInformation {
      instagram
      email
      telephoneNumber
      address
      postcode
    }
  }
`;

const Container = () => (
  <StaticQuery query={query} render={data => <OrganisationData {...data} />} />
);

const OrganisationData = ({
  contactInfo,
  allBannerImages,
  allContentfulTeamMember
}) => {
  const employee = pipe(
    get("edges"),
    map("node"),
    map(({ jobTitle, name, photo }) => ({
      jobTitle,
      name,
      image: `https:${get("fluid.src", photo)}`
    }))
  )(allContentfulTeamMember);
  const image = pipe(
    get("edges"),
    map(
      pipe(
        get("page.heroImage.fluid.src"),
        concat("https:"),
        join("")
      )
    )
  )(allBannerImages);
  const {
    telephoneNumber: telephone,
    address: streetAddress,
    postcode: postalCode
  } = contactInfo;
  const orgData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    image,
    "@id": "https://macangus-wainwright.com",
    name: "MacAngus & Wainwright",
    description: "Bespoke City Tailors serving London for over 100 years",
    logo:
      "https://images.ctfassets.net/q8a43t0q37vf/2oLLFugs1X4SyBCLwyOldt/cfe47bb4ed1b210d1ce94c504914a202/Macangus_Wainwright_BC_FRONT_SPOT.jpg",
    address: {
      "@type": "PostalAddress",
      streetAddress,
      addressLocality: "London",
      addressRegion: "England",
      postalCode,
      addressCountry: "GB"
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 51.513035,
      longitude: -0.085652
    },
    sameAs: [
      "https://www.facebook.com/macanguswainwright/",
      "https://www.instagram.com/macanguswainwright/"
    ],
    url: "https://macangus-wainwright.com",
    telephone,
    openingHours: "Mo,Tu,We,Th,Fr 09:00-17:00",
    employee
  };
  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(orgData) }}
    />
  );
};

OrganisationData.propTypes = {
  allBannerImages: PropTypes.shape({
    edges: PropTypes.array
  }).isRequired,
  allContentfulTeamMember: PropTypes.shape({
    edges: PropTypes.array
  }).isRequired,
  contactInfo: PropTypes.shape({
    instagram: PropTypes.string,
    email: PropTypes.string,
    telephoneNumber: PropTypes.string,
    address: PropTypes.string,
    postcode: PropTypes.string
  }).isRequired
};

export default Container;

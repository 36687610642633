import React, { Fragment } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const Links = ({ pages }) => (
  <Fragment>
    <h4>Links</h4>
    <ul>
      {pages.map(page => (
        <li key={page.id}>
          <Link
            to={`/${page.slug}`}
            title={`${page.title} - ${page.introHeaderSubtitle}`}
          >
            {`${page.title} - ${page.introHeaderSubtitle}`}
          </Link>
        </li>
      ))}
    </ul>
  </Fragment>
);

Links.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
      introHeaderSubtitle: PropTypes.string,
      title: PropTypes.string
    })
  )
};

Links.defaultProps = {
  pages: []
};

export default Links;

import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import { get, map, pipe, remove } from "lodash/fp";
import { Container } from "../LayoutUtils";
import styles from "./style.module.scss";
import Address from "./Address";
import Contact from "./Contact";
import Links from "./Links";

const Footer = ({ data }) => {
  const {
    telephoneNumber,
    email,
    instagram,
    address,
    postcode
  } = data.contentfulContactInformation;

  const { contentfulHeroImage } = data;

  const pages = pipe(
    get("pages.edges"),
    map("page"),
    remove({ slug: "faqs" })
  )(data);

  const services = pipe(
    get("services.edges"),
    map(
      pipe(
        get("service"),
        service => ({
          id: service.id,
          slug: `services/${service.slug}`,
          title: service.title,
          introHeaderSubtitle: service.subtitle
        })
      )
    )
  )(data);

  const otherPages = [
    {
      id: "cloths",
      slug: "cloths",
      title: "Our cloths",
      introHeaderSubtitle: "the foundation of every bespoke suit"
    }
  ];

  return (
    <div className={styles.footer}>
      <div className={styles.contentWrapper}>
        <Container>
          <div className="row">
            <div className="col-sm-4">
              <Address address={address} postcode={postcode} />
            </div>
            <div className="col-sm-4">
              <Contact
                telephoneNumber={telephoneNumber}
                email={email}
                instagram={instagram}
              />
            </div>
            <div className="col-sm-4">
              <Links pages={[...otherPages, ...pages, ...services]} />
            </div>
          </div>
          <div className={styles.copyright}>
            MacAngus & Wainwright Bespoke City Tailors &copy;{" "}
            {` ${new Date().getFullYear()}`}
          </div>
        </Container>
      </div>
      <Image
        title="Handmade in the City of London"
        alt="MacAngus & Wainwright, Bespoke City Tailors"
        className={styles.image}
        style={{ position: "absolute" }}
        {...contentfulHeroImage.backgroundImage}
      />
    </div>
  );
};

Footer.propTypes = {
  data: PropTypes.shape({
    contentfulContactInformation: PropTypes.shape({
      instagram: PropTypes.string,
      email: PropTypes.string,
      telephoneNumber: PropTypes.string
    })
  }).isRequired
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHeroImage(
          id: { eq: "6471eb64-cf5a-5e37-9177-c81180040f31" }
        ) {
          id
          title
          subtitle
          backgroundImage {
            id
            fluid(maxWidth: 1000) {
              ...fluidImage
            }
          }
        }
        contentfulContactInformation {
          instagram
          email
          telephoneNumber
          address
          postcode
        }
        pages: allContentfulPage(filter: { showInFooter: { eq: true } }) {
          edges {
            page: node {
              id
              slug
              title
              introHeaderSubtitle
            }
          }
        }
        services: allContentfulService(sort: { fields: [title], order: ASC }) {
          edges {
            service: node {
              ...service
            }
          }
        }
      }
    `}
    render={data => <Footer {...props} data={data} />}
  />
);

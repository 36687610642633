import React from "react";
import PropTypes from "prop-types";
import { H3, P } from "../Type";
import MailingListForm from "../MailingListForm";
import Modal from "../Modal";
import styles from "./styles.module.scss";

const MailingListModal = ({ modalVisible, onClose }) => {
  return (
    <Modal visible={modalVisible} onClose={onClose}>
      <div className={styles.mailingListModalContent}>
        <H3>Join our mailing list</H3>
        <P>
          We would really like to stay in touch. We will email you every now and
          again to let you know about any offers we are running or other
          exciting news.
        </P>
        <MailingListForm onSuccess={onClose} />
      </div>
    </Modal>
  );
};

MailingListModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MailingListModal;

/* eslint-disable react/button-has-type */

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

const Button = ({ children, secondary, type, onClick }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={classNames(styles.button, {
        [styles.secondary]: secondary
      })}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
  type: PropTypes.oneOf(["submit", "button", "reset"]),
  onClick: PropTypes.func
};

Button.defaultProps = {
  secondary: false,
  type: "button",
  onClick: () => {}
};

const LinkButton = ({
  children,
  secondary,
  href,
  className,
  target,
  disabled
}) => {
  return (
    <a
      disabled={disabled}
      target={target}
      href={href}
      className={classNames(
        styles.button,
        {
          [styles.secondary]: secondary,
          [styles.disabled]: disabled
        },
        className
      )}
    >
      {children}
    </a>
  );
};

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  target: PropTypes.string
};

LinkButton.defaultProps = {
  secondary: false,
  className: null,
  target: "_self"
};

export { LinkButton };

export default Button;

import React, { Component } from "react";
import { includes, pull } from "lodash/fp";
import shortId from "shortid";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

const SCOLL_LOCK_CLASS_NAME = "scroll-locked";

const lockBodyScroll = className => visible => {
  if (typeof document !== "undefined") {
    let classes = document.body.className.split(" ");
    if (visible) {
      if (includes(className, classes)) return;
      classes.push(className);
    } else {
      classes = pull(className, classes);
    }
    document.body.className = classes.join(" ");
  }
};

const formatName = (name = "") => name.split(" ").join("-");

class Modal extends Component {
  constructor(props) {
    super(props);
    const { name } = props;
    const cssLock = [
      SCOLL_LOCK_CLASS_NAME,
      formatName(name),
      shortId.generate()
    ].join("-");
    this.lockBodyScroll = lockBodyScroll(cssLock);
  }

  componentDidMount() {
    const { visible } = this.props;
    this.lockBodyScroll(visible);
  }

  componentDidUpdate() {
    const { visible } = this.props;
    this.lockBodyScroll(visible);
  }

  componentWillUnmount() {
    this.lockBodyScroll(false);
  }

  render() {
    const { children, visible, onClose } = this.props;
    return (
      <div
        className={classNames(styles.modal, {
          [styles.modalVisible]: visible
        })}
      >
        <div className={styles.modalHeader}>
          <button
            type="button"
            onClick={onClose}
            className={styles.closeButtonContainer}
          >
            <span className={styles.closeButton} />
          </button>
        </div>
        <div className={styles.modalContent}>{children}</div>
      </div>
    );
  }
}

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string
};

Modal.defaultProps = {
  name: ""
};

export default Modal;

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

const DEFAULT_SHOW_AFTER = 10 * 1000; // 10 seconds

const DEFAULT_COOKIE_EXPIRY_DAYS = 30;

class CookieModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false
    };
  }

  componentWillMount() {
    const { cookie, showAfter } = this.props;
    const hasSeenModal = Boolean(Cookies.get(cookie));
    if (!hasSeenModal) {
      setTimeout(() => {
        this.setState(() => ({ modalVisible: true }));
      }, showAfter);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { cookie, expires } = this.props;
    const { modalVisible } = nextState;
    if (modalVisible) {
      Cookies.set(cookie, true, { expires });
    }
  }

  close = () => {
    this.setState(() => ({ modalVisible: false }));
  };

  render() {
    const { modalVisible } = this.state;
    const { Modal } = this.props;
    return (
      <Fragment>
        <Modal modalVisible={modalVisible} onClose={this.close} />
      </Fragment>
    );
  }
}

CookieModal.propTypes = {
  cookie: PropTypes.string.isRequired,
  Modal: PropTypes.func.isRequired,
  expires: PropTypes.number,
  showAfter: PropTypes.number
};

CookieModal.defaultProps = {
  expires: DEFAULT_COOKIE_EXPIRY_DAYS,
  showAfter: DEFAULT_SHOW_AFTER
};

export default CookieModal;

import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MailingListModal from "../components/MailingListModal";
import CookieModal from "../components/CookieModal";
import styles from "./style.module.scss";
import "../scss/style.scss";

const COOKIE = "seen_mailing_list_modal";

const Layout = ({ children }) => (
  <React.Fragment>
    <SEO />
    <div className="page">
      <div id="wrapper" className="wrapper">
        <Header />

        <div className={styles.content}>{children}</div>
      </div>
      <Footer />
    </div>
    <CookieModal Modal={MailingListModal} cookie={COOKIE} />
    <div style={{ display: "none" }}>Site by dom@dombarker.co.uk</div>
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default Layout;

import React from "react";
import PropTypes from "prop-types";

const Container = ({ children, ...rest }) => (
  <div className="container" {...rest}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired
};

export default Container;

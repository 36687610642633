import React from "react";
import PropTypes from "prop-types";

import DropDown from "../Dropdown";

const ServicesDropdown = ({ services }) => {
  return <DropDown label="Services" items={services} />;
};

ServicesDropdown.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

export default ServicesDropdown;

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import style from "./input.module.scss";

const Errors = ({ errors }) => {
  if (errors) {
    return (
      <div className={style.errors}>
        {errors.map(e => (
          <span key={e}>{e}</span>
        ))}
      </div>
    );
  }
  return null;
};

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string)
};

Errors.defaultProps = {
  errors: null
};

const Input = ({ name, value, errors, onBlur, onChange, placeholder }) => (
  <div className={classNames(style.input, { [style.inputError]: !!errors })}>
    <input
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
    />
    <Errors errors={errors} />
  </div>
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

Input.defaultProps = {
  onBlur: () => {},
  placeholder: null,
  errors: null
};

export default Input;

import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import { Input, NetlifyForm } from "../Forms";

const FIELDS = ["name", "email"];

const MailingListForm = ({
  form,
  errors,
  onSubmit,
  onBlur,
  onChange,
  formName
}) => (
  <div>
    <NetlifyForm name={formName} onSubmit={onSubmit}>
      <input type="hidden" name="firstName" />
      <input type="hidden" name="lastName" />
      {FIELDS.map(name => (
        <Input
          key={name}
          placeholder={`Your ${name}`}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          value={form[name]}
          errors={errors[name]}
        />
      ))}
      <Button type="submit">Join mailing list</Button>
    </NetlifyForm>
  </div>
);

MailingListForm.propTypes = {
  form: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired
};

export default MailingListForm;

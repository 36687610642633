import React, { Fragment } from "react";
import PropTypes from "prop-types";

const Address = ({ address, postcode }) => (
  <Fragment>
    <h4>Address</h4>
    <ul>
      <li>{address}</li>
      <li>City of London</li>
      <li>{postcode}</li>
    </ul>
  </Fragment>
);

Address.propTypes = {
  address: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired
};

export default Address;

import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { get, map, pipe } from "lodash/fp";

import ServicesDropdown from "./ServicesDropdown";

const getServices = pipe(
  get("allServices.edges"),
  map("service"),
  map(s => ({
    ...s,
    text: s.title,
    url: `/services/${s.slug}`
  }))
);

const query = graphql`
  {
    allServices: allContentfulService {
      edges {
        service: node {
          id
          slug
          title
        }
      }
    }
  }
`;

const ServicesDropDownContainer = props => (
  <StaticQuery
    query={query}
    render={data => (
      <ServicesDropdown services={getServices(data)} {...props} />
    )}
  />
);

export default ServicesDropDownContainer;

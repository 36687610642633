import React from "react";
import PropTypes from "prop-types";

const NetlifyForm = ({ children, onSubmit, name }) => {
  return (
    <form name={name} onSubmit={onSubmit} data-netlify="true">
      <input type="hidden" name="form-name" value={name} />
      {children}
    </form>
  );
};

NetlifyForm.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default NetlifyForm;

import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import Menu from "./Menu";
import Hamburger from "./Hamburger";
import MenuMobile from "./MenuMobile";
import Logo from "../../../static/logo.svg";
import { header, container, logo } from "./style.module.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false
    };
  }

  toggleMenu = () =>
    this.setState(prevState => ({
      menuActive: !prevState.menuActive
    }));

  render() {
    const { menuActive } = this.state;
    return (
      <div className={header}>
        <div className={classNames(container, "container")}>
          <div className={logo}>
            <Link to="/">
              <img src={Logo} alt="MacAngus & Wainwright" />
              <span>MacAngus & Wainwright</span>
            </Link>
          </div>
          <MenuMobile active={menuActive} />
          <Menu />
          <Hamburger toggleMenu={this.toggleMenu} />
        </div>
      </div>
    );
  }
}

export default Header;

import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import favicon from "../../../static/favicon.png";
import OrganisationData from "./OrganisationData";

const MAX_TITLE_LENGTH = 60;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const buildTitle = (title, defaultTitle) => {
  if (!title) return defaultTitle;
  if (title.length >= MAX_TITLE_LENGTH)
    return `${title.substring(0, MAX_TITLE_LENGTH - 3)}...`;
  const concat = `${title} | ${defaultTitle}`;
  if (concat.length >= MAX_TITLE_LENGTH) {
    return title;
  }
  return concat;
};

const DEFAULT_OG_IMAGE =
  "https://images.ctfassets.net/q8a43t0q37vf/5M5JhgWwTVt9qaQpxljGKu/c9749e787939fe2b7b5b80c7994004ec/image.png?w=1000&h=562";

const SEO = ({ title, lang, description, children, image }) => (
  <StaticQuery
    query={detailsQuery}
    render={data => {
      return [
        <Helmet
          key={1}
          htmlAttributes={{
            lang
          }}
          title={buildTitle(title, data.site.siteMetadata.title)}
          link={[
            { rel: "shortcut icon", type: "image/png", href: `${favicon}` }
          ]}
        >
          <meta name="description" content={description} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@macangusw" />
          <meta
            property="og:title"
            content={title || data.site.siteMetadata.title}
          />

          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
          {children}
        </Helmet>,
        <OrganisationData key={2} />
      ];
    }}
  />
);

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.string
};

SEO.defaultProps = {
  lang: "en",
  title: null,
  description:
    "MacAngus & Wainwright are bespoke city tailors, serving the Square Mile and beyond for over 75 years.",
  children: null,
  image: DEFAULT_OG_IMAGE
};

export default SEO;

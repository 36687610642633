import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, Link, graphql } from "gatsby";
import { pipe, get, map } from "lodash/fp";
import ServicesDropdown from "../../ServicesDropdown";
import styles from "./style.module.scss";

const Menu = ({ data }) => {
  const menuLinks = pipe(
    get("pages.edges"),
    map("menuPage")
  )(data);
  return (
    <div id="main-menu" className={styles.mainMenu}>
      <ul>
        <li>
          <ServicesDropdown />
        </li>
        {menuLinks.map(link => (
          <li key={link.id}>
            <Link to={`/${link.link}`} activeClassName={styles.active}>
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

Menu.propTypes = {
  data: PropTypes.shape({
    contentfulContactInformation: PropTypes.shape({
      telephoneNumber: PropTypes.string
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        menuLinks: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string
          })
        )
      })
    })
  }).isRequired
};

const query = graphql`
  query {
    ...getMenuPages
  }
`;

export default () => (
  <StaticQuery query={query} render={data => <Menu data={data} />} />
);

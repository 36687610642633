import React, { Component } from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./style.module.scss";

class DropDown extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        url: PropTypes.string
      })
    ).isRequired
  };

  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  toggle = () => {
    this.setState(state => ({
      expanded: !state.expanded
    }));
  };

  render() {
    const { expanded } = this.state;
    const { label, items } = this.props;
    return (
      <span>
        <button
          type="button"
          className={classnames(styles.clicker, {
            [styles.clickerActive]: expanded
          })}
          onClick={this.toggle}
        >
          {label}
        </button>
        <ul
          className={classnames(styles.dropDown, {
            [styles.dropDownVisible]: expanded
          })}
        >
          {items.map(({ id, text, url }) => (
            <li key={id}>
              <Link to={`${url}`}>{text}</Link>
            </li>
          ))}
        </ul>
      </span>
    );
  }
}

export default DropDown;
